import { Injectable } from '@angular/core';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  ChartTimeSeries,
  TimeSeries,
  TimeSeriesIdentifierInput,
} from '@backoffice-frontend/time-series-identifier/api';
import { map, Observable } from 'rxjs';
import { ExportTableDataService } from '../infrastructure/export-table.data.service';
import { ExportTableDto } from '../models/export-table-data';

@Injectable({ providedIn: 'root' })
export class ExportTableFacade {
  constructor(protected service: ExportTableDataService) {}

  public getExportTableData$(
    identifiers: TimeSeriesIdentifierInput[],
    timeFrame?: TimeFrame
  ): Observable<Array<ExportTableDto>> {
    // timeFrame?: TimeFrame // ids: TimeSeriesIdentifierInput[],
    return this.service
      .getTimeSeriesChartData$(identifiers, timeFrame, false)
      .pipe(
        map((values: ChartTimeSeries[]) => {
          const array = new Array<ExportTableDto>();

          values.forEach((chartTimeSeries: ChartTimeSeries) => {
            chartTimeSeries.values.forEach((timeSeries: TimeSeries) => {
              array.push({
                tradeDate: timeSeries.tradeDate,
                value: timeSeries.value,
                frontOfficeName: chartTimeSeries.frontOfficeName,
                areaMarketArea: chartTimeSeries.areaMarketArea?.name,
                unit: chartTimeSeries.unit,
                deliveryDay: chartTimeSeries.deliveryDay,
                longName: chartTimeSeries.longName,
              });
            });
          });

          return array;
        })
      );
  }
}
