import { Injectable } from '@angular/core';
import {
  IndividualTimeFrame,
  TimeFrame,
  TimeFrameUtilService,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  TimeSeriesAnalysisIdentifierDataLocalized,
  TimeSeriesIdentifierInput,
} from '@backoffice-frontend/time-series-identifier/api';
import { Area } from '@clean-code/backoffice/area/util-api';
import { EnergySourceEnum } from '@clean-code/shared/bo/util-enum';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { SpreadCollection, SpreadTable } from '../models/spread';

@Injectable({ providedIn: 'root' })
export class SpreadDataService {
  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    symbols: TimeSeriesAnalysisIdentifierDataLocalized[],
    timeFrame: TimeFrame
  ): Observable<SpreadCollection> {
    const query = `
    query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput){
      spreadGetAll (symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame){
        spreads
        spreadsAvg
        avg
        timeSeries
        unit
      }
    }`;

    const variables = {
      symbols: symbols,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<SpreadCollection>(query, variables);
  }

  getTable$(
    symbols: TimeSeriesAnalysisIdentifierDataLocalized[],
    timeFrame: TimeFrame
  ): Observable<SpreadTable> {
    const query = `
    query($symbols: [TimeSeriesInput!], $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput){
      spreadGetTable (symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame){
          current
          avg {
            spread1
            spread2
            values
            {
              key
              value
              {
                spread1Value
                spread2Value
                avg
              }
            }
          }
      }
    }`;

    const variables = {
      symbols,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<SpreadTable>(query, variables);
  }

  withDynamicInput$(
    symbols: TimeSeriesIdentifierInput[],
    timeFrame: TimeFrame
  ): Observable<SpreadCollection> {
    const query = `
    query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput){
      spreadsWithDynamicInput (symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame){
        spreads
        spreadsAvg
        avg
        timeSeries
        unit
      }
    }`;

    const variables = {
      symbols,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<SpreadCollection>(query, variables);
  }

  //TODO: possibly better to move this to area.service?
  //      but area.service is using graphql.net at the moment
  public getAllMarketAreasByEnergySource$(
    energySource: EnergySourceEnum
  ): Observable<Area[]> {
    const query = `
        query ($energySource: EnergySourceEnum!) {
          marketAreas (
            where: {
              and: [ 
                {energySource: {eq: $energySource } }, 
                {disabled: {eq: false}} 
              ] 
            }
          ) {
            id
            name
            longName
            country
            energySource
            disabled
            timeZoneId
            timeRangeFrom
            timeRangeTo
            }
        }
    `;

    const variables = { energySource: energySource };

    return this.graphqlService.query<Area[]>(query, variables);
  }
}
