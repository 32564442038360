import { Injectable } from '@angular/core';
import {
  EnergySource,
  EnergySourceLocalized,
  EnergySourceService,
  TimeFrame,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  TimeSeriesAnalysisIdentifierDataLocalized,
  TimeSeriesFacade,
  TimeSeriesIdentifierInput,
} from '@backoffice-frontend/time-series-identifier/api';
import { EnergySourceEnum } from '@clean-code/shared/bo/util-enum';
import { ID, PagingResponse } from '@clean-code/shared/common';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SpreadCollection, SpreadTable } from '../models/spread';
import { SpreadDataService } from './../infrastructure/spread.data.service';

@Injectable({ providedIn: 'root' })
export class IndividualSpreadsChartFacade {
  constructor(
    private energySourceService: EnergySourceService,
    private timeSeriesDataService: TimeSeriesFacade,
    private spreadDataService: SpreadDataService
  ) {}

  getEnergySources$(): Observable<EnergySource[]> {
    return this.energySourceService
      .getAll$()
      .pipe(map((response: PagingResponse<EnergySource>) => response.items));
  }

  getEnergySourcesLocalized$(): Observable<EnergySourceLocalized[]> {
    return this.energySourceService
      .getAllLocalized$()
      .pipe(
        map((response: PagingResponse<EnergySourceLocalized>) => response.items)
      );
  }
  getByEnergySource$(energySource: EnergySourceEnum) {
    return this.spreadDataService
      .getAllMarketAreasByEnergySource$(energySource)
      .pipe(shareReplay(1));
  }
  timeSeriesIdentifierDataByEnergySource$(
    energySourceId: ID
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.timeSeriesDataService.timeSeriesIdentifierDataByEnergySource$(
      energySourceId
    );
  }

  timeSeriesIdentifierDataByMarketArea$(
    areaId: ID,
    from: Dayjs = null,
    to: Dayjs = null
  ): Observable<TimeSeriesAnalysisIdentifierDataLocalized[]> {
    return this.timeSeriesDataService.timeSeriesIdentifierDataByMarketArea$(
      areaId,
      from,
      to
    );
  }

  getAll$(
    symbols: TimeSeriesAnalysisIdentifierDataLocalized[],
    timeFrame: TimeFrame
  ): Observable<SpreadCollection> {
    return this.spreadDataService.getAll$(symbols, timeFrame);
  }

  getTable$(
    symbols: TimeSeriesAnalysisIdentifierDataLocalized[],
    timeFrame: TimeFrame
  ): Observable<SpreadTable> {
    return this.spreadDataService.getTable$(symbols, timeFrame);
  }

  withDynamicInput$(
    symbols: TimeSeriesIdentifierInput[],
    timeFrame: TimeFrame
  ): Observable<SpreadCollection> {
    return this.spreadDataService.withDynamicInput$(symbols, timeFrame);
  }
}
