import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@jsverse/transloco';
// import { NgChartsModule } from 'ng2-charts';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ChartJSContainerComponent } from './components/chart-container/chart-container.component';
// import { LineChartComponent } from './components/line-chart/line-chart.component';
// import { PieChartComponent } from './components/pie-chart/pie-chart.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    // NgChartsModule,
    TranslocoModule,
  ],
  declarations: [
    // LineChartComponent,
    // DoughnutChartComponent,
    // PieChartComponent,
    ChartJSContainerComponent,
    BarChartComponent,
  ],
  exports: [
    // LineChartComponent,
    // NgChartsModule,
    // DoughnutChartComponent,
    // PieChartComponent,
    ChartJSContainerComponent,
    BarChartComponent,
  ],
})
export class ChartJsLibModule {}
