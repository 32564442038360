import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MarketColumnDetail } from '../models/market-dashboard';

export interface IMarketColumnDetailState
  extends EntityState<MarketColumnDetail> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboardDetailsColumn', resettable: true })
export class MarketColumnDetailStore extends EntityStore<
  IMarketColumnDetailState,
  MarketColumnDetail
> {
  constructor() {
    super();
  }
}
