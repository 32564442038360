import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MarketGroupStore } from '../+state/group.store';
import { MarketGroup } from '../models/market-dashboard';
import { MarketWidgetDataService } from './widget.data.service';

@Injectable({ providedIn: 'root' })
export class MarketGroupDataService {
  public static readonly marketGroupParams = `
        id
        name
        type
        items
        {
            ${MarketWidgetDataService.marketWidgetParams}
        }
        settings
        {
            id
            position
            showTitle
        }
        summaryColumnId
        detailColumnId
    `;

  private readonly entityName = 'marketGroup';

  constructor(
    private store: MarketGroupStore,
    private graphqlService: GraphqlService
  ) {}

  getAll$(id: ID): Observable<MarketGroup[]> {
    const query = `
            query($id: Int!) {
                ${this.entityName}GetAll(id: $id) {
                    ${MarketGroupDataService.marketGroupParams}
                }
            }
        `;

    return this.graphqlService.query<MarketGroup[]>(query, { id });
  }

  getById$(id: ID): Observable<MarketGroup> {
    const query = `
            query($id: Int!) {
                ${this.entityName}GetById(id: $id) {
                    ${MarketGroupDataService.marketGroupParams}
                }
            }
        `;

    return this.graphqlService.query<MarketGroup>(query, { id });
  }

  add$(input: MarketGroup): Observable<MarketGroup> {
    const mutation = `
            mutation ($input: MarketGroupInput!) {
                ${this.entityName}Add(input: $input)
                {
                    ${MarketGroupDataService.marketGroupParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketGroup>(mutation, variables)
      .pipe(tap((value: MarketGroup) => this.store.upsert(value.id, input)));
  }

  update$(input: MarketGroup): Observable<MarketGroup> {
    const mutation = `
            mutation ($input: MarketGroupInput!) {
                ${this.entityName}Update(input: $input)
                {
                    ${MarketGroupDataService.marketGroupParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketGroup>(mutation, variables)
      .pipe(tap((value: MarketGroup) => this.store.upsert(value.id, value)));
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
            mutation {
                ${this.entityName}Delete(id: ${id})
            }
        `;

    return this.graphqlService
      .mutation<boolean>(mutation)
      .pipe(tap(() => this.store.remove(id)));
  }
}
