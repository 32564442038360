import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MarketColumn } from '../models/market-dashboard';
import { MarketColumnStore } from './../+state/column.store';
import { MarketColumnDataService } from './../infrastructure/column.data.service';

@Injectable({ providedIn: 'root' })
export class ColumnFacade {
  constructor(
    private service: MarketColumnDataService,
    private marketColumnStore: MarketColumnStore
  ) {}

  add$(input: MarketColumn) {
    return this.service
      .add$(input)
      .pipe(tap((x: MarketColumn) => this.marketColumnStore.upsert(x.id, x)));
  }

  update$(input: MarketColumn) {
    return this.service
      .update$(input)
      .pipe(tap((x: MarketColumn) => this.marketColumnStore.upsert(x.id, x)));
  }

  getAll$(dashboardId: ID): Observable<MarketColumn[]> {
    return this.service.getAll$(dashboardId);
  }

  getById$(_id: ID) {
    return this.service.getById$(_id);
  }

  delete$(id: ID) {
    return this.service
      .delete$(id)
      .pipe(tap(() => this.marketColumnStore.remove(id)));
  }
}
