import { Injectable } from '@angular/core';
import { ID, Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { MarketColumn } from '../models/market-dashboard';
import { IMarketColumnState, MarketColumnStore } from './column.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'position',
  sortByOrder: Order.ASC,
})
export class MarketColumnQuery extends QueryEntity<
  IMarketColumnState,
  MarketColumn
> {
  public columns$ = (id: ID) =>
    this.selectAll({
      filterBy: (entity) => entity.dashboardId === id,
    });

  public column$ = (id: ID) => this.select(id);
  public columnCount$ = (id: ID) =>
    this.selectCount((x) => x.dashboardId === id);

  constructor(store: MarketColumnStore) {
    super(store);
  }
}
