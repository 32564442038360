import { Injectable } from '@angular/core';
import {
  IndividualTimeFrame,
  TimeFrame,
  TimeFrameUtilService,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { TimeSeriesIdentifierInput } from '@backoffice-frontend/time-series-identifier/api';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import {
  CorrelationModel,
  MarketChartCorrelation,
} from '../models/correlation.model';
import { CorrelationType } from '../models/widget-type-config';

@Injectable({ providedIn: 'root' })
export class MarketDataWidgetService {
  constructor(private graphqlService: GraphqlService) {}

  public marketTableCorrelation$(
    symbols: TimeSeriesIdentifierInput[],
    timeFrame: TimeFrame,
    weeks: boolean,
    months: boolean,
    years: boolean
  ): Observable<CorrelationModel[]> {
    const query = `
    query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput, $weeks: Boolean!, $months: Boolean!, $years: Boolean!){
            marketTableCorrelation(symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame, weeks: $weeks, months: $months, years: $years)
            {
              base
              comparison
              type
              value
              error
            }
          }`;

    const variables = {
      symbols: symbols,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
      weeks: weeks,
      months: months,
      years: years,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<CorrelationModel[]>(query, variables);
  }

  marketChartCorrelation$(
    identifier: TimeSeriesIdentifierInput,
    symbols: TimeSeriesIdentifierInput[],
    timeFrame: TimeFrame,
    type: CorrelationType
  ): Observable<MarketChartCorrelation[]> {
    const query = `
      query($identifier:TimeSeriesInput!, $symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput, $type: CorrelationType!){
              marketChartCorrelation(identifier: $identifier, symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame, type: $type)
              {
                base
                comparison
                type
                values
                {
                  key
                  value
                }
                error
              }
            }`;

    const variables = {
      identifier: identifier,
      symbols: symbols,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
      type: type,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<MarketChartCorrelation[]>(
      query,
      variables
    );
  }
}
