import { Injectable } from '@angular/core';
import { DateParserService, ID, ObjectHelper } from '@clean-code/shared/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MarketColumnQuery } from '../+state/column.query';
import { MarketColumnStore } from '../+state/column.store';
import { MarketDashboardQuery } from '../+state/dashboard.query';
import { MarketDashboardStore } from '../+state/dashboard.store';
import { MarketColumnDetailQuery } from '../+state/details-column.query';
import { MarketColumnDetailStore } from '../+state/details-column.store';
import { MarketGroupQuery } from '../+state/group.query';
import { MarketGroupStore } from '../+state/group.store';
import { MarketWidgetQuery } from '../+state/widget.query';
import { MarketWidgetStore } from '../+state/widget.store';
import {
  MarketColumn,
  MarketDashboard,
  MarketWidget,
} from '../models/market-dashboard';

@Injectable({ providedIn: 'root' })
export class DashboardStateService {
  public editMode$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public columns$ = this.marketDashboardQuery
    .selectActiveId()
    .pipe(switchMap((id: ID) => this.marketColumnQuery.columns$(id)));

  public column$ = this.marketDashboardQuery
    .selectActiveId()
    .pipe(switchMap((id: ID) => this.marketColumnQuery.column$(id)));

  public columnCount$ = this.marketDashboardQuery
    .selectActiveId()
    .pipe(switchMap((id: ID) => this.marketColumnQuery.columnCount$(id)));

  public groups$ = (columnId: ID) => this.marketGroupQuery.groups$(columnId);
  public detailsGroups$ = (columnId: ID) =>
    this.marketGroupQuery.detailsGroups$(columnId);

  public detailsColumns$ = (columnId: ID) =>
    this.marketColumnDetailQuery.columns$(columnId);
  public columnDetailsCount$ = (columnId: ID) =>
    this.marketColumnDetailQuery.columnCount$(columnId);

  public widgets$ = (groupId: ID) => this.marketWidgetQuery.widgets$(groupId);
  public widgetsCount = (groupId: ID) =>
    this.marketWidgetQuery.widgetsCount(groupId);

  public constructor(
    private marketDashboardStore: MarketDashboardStore,
    private marketColumnStore: MarketColumnStore,
    private marketDetailsColumnStore: MarketColumnDetailStore,
    private marketGroupStore: MarketGroupStore,
    private marketWidgetStore: MarketWidgetStore,
    private marketColumnQuery: MarketColumnQuery,
    private marketGroupQuery: MarketGroupQuery,
    private marketWidgetQuery: MarketWidgetQuery,
    private marketColumnDetailQuery: MarketColumnDetailQuery,
    private marketDashboardQuery: MarketDashboardQuery
  ) {}

  public get dashboard(): MarketDashboard {
    return this.marketDashboardQuery.getActive() as MarketDashboard;
  }

  public set dashboard(d: MarketDashboard) {
    const dash = ObjectHelper.cloneObject(d);
    delete dash.cols;
    this.marketDashboardStore.upsert(dash.id, dash);

    d.cols.forEach((col) => {
      const column = ObjectHelper.cloneObject(col);
      delete column.summaries;
      delete column.details;

      column.hasDetails = col.details?.length > 0;

      this.marketColumnStore.upsert(column.id, column);

      col.details.forEach((dcol) => {
        const detail = ObjectHelper.cloneObject(dcol);
        delete detail.items;

        this.marketDetailsColumnStore.upsert(detail.id, detail);

        dcol.items.forEach((group) => {
          const g = ObjectHelper.cloneObject(group);
          delete g.items;
          this.marketGroupStore.upsert(g.id, g);

          this.marketWidgetStore.upsertMany(
            group.items.map((x: MarketWidget) => this.processWidgetData(x))
          );
        });
      });

      col.summaries.forEach((group) => {
        const g = ObjectHelper.cloneObject(group);
        delete g.items;
        this.marketGroupStore.upsert(g.id, g);
        this.marketWidgetStore.upsertMany(
          group.items.map((x: MarketWidget) => this.processWidgetData(x))
        );
      });
    });

    this.marketDashboardStore.setActive(d.id);
  }

  public get activeDashboardId$(): Observable<ID> {
    return this.marketDashboardQuery.selectActiveId();
  }

  public toggleEditMode() {
    this.editMode$.next(!this.editMode$.value);
  }

  public toggleColumnExpended(value: MarketColumn) {
    const newValue = { ...value, extended: !value.extended };

    this.marketColumnStore.update(value.id, newValue);
  }

  public resetStores() {
    this.marketDashboardStore.reset();
    this.marketColumnStore.reset();
    this.marketDetailsColumnStore.reset();
    this.marketGroupStore.reset();
    this.marketWidgetStore.reset();
  }

  private processWidgetData(w: MarketWidget): MarketWidget {
    if (w) {
      w.settings = JSON.parse(
        w.settings as any,
        DateParserService.getJsonDateReviver()
      );

      w.dataSources.forEach((x: any) => {
        x.settings = JSON.parse(
          x.settings as string,
          DateParserService.getJsonDateReviver()
        );

        //TODO: Delete after successfully saved to db on customer!
        const settingsMigration = x.settings as any;
        if (
          settingsMigration.sortOrder !== null &&
          settingsMigration.sortOrder !== undefined
        ) {
          x.settings.identifier.sortOrder = settingsMigration.sortOrder;

          delete settingsMigration.sortOrder;
        }

        if (
          settingsMigration.ignoreGaps === true ||
          settingsMigration.ignoreGaps === false
        ) {
          x.settings.identifier.ignoreGaps = settingsMigration.ignoreGaps;

          delete settingsMigration.ignoreGaps;
        }

        if (settingsMigration.calculationType) {
          x.settings.identifier.calculationType =
            settingsMigration.calculationType;

          delete settingsMigration.calculationType;
        }
      });
    }
    return w;
  }
}
