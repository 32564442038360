type ExportTablePermissionValues =
  | 'export-table.filter'
  | 'export-table.export';

export class ExportTablePermissions {
  public static readonly exportTableFilter: ExportTablePermissionValues =
    'export-table.filter';

  public static readonly exportTableExport: ExportTablePermissionValues =
    'export-table.export';
}
