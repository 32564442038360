import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MarketWidget } from '../models/market-dashboard';

export interface IMarketWidgetState extends EntityState<MarketWidget> {}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboardWidget', resettable: true })
export class MarketWidgetStore extends EntityStore<
  IMarketWidgetState,
  MarketWidget
> {
  constructor() {
    super();
  }
}
