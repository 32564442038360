import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AroonTrendIconComponent } from './aroon-trend-icon.component';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [AroonTrendIconComponent],
  exports: [AroonTrendIconComponent],
})
export class AroonTrendIconModule {}
