import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MarketDashboard } from '../models/market-dashboard';
import { IMarketDasboardState, MarketDashboardStore } from './dashboard.store';

@Injectable({ providedIn: 'root' })
export class MarketDashboardQuery extends QueryEntity<
  IMarketDasboardState,
  MarketDashboard
> {
  constructor(store: MarketDashboardStore) {
    super(store);
  }
}
