import { Component, Input } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowDown as faLongArrowDownLight } from '@fortawesome/pro-light-svg-icons';
import { faLongArrowDown as faLongArrowDownSolid } from '@fortawesome/pro-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fof-aroon-trend-icon',
  templateUrl: './aroon-trend-icon.component.html',
  styleUrls: ['./aroon-trend-icon.component.scss'],
})
export class AroonTrendIconComponent {
  public rotate$ = new BehaviorSubject<number>(0);
  public arrowIconLight = faLongArrowDownLight;
  public arrowIconSolid = faLongArrowDownSolid;

  @Input() public set delta(value: number) {
    this.rotate$.next(this.rotate(value));
  }

  @Input() public iconSize: SizeProp = '1x';

  @Input() public iconStyle: 'light' | 'solid' = 'light';

  public getColor(degree: number): string {
    if (degree === 180 || degree === 225) {
      return '#75c41a';
    }

    if (degree === 315 || degree === 360) {
      return '#eddb1e';
    }

    return '';
  }

  private rotate(delta: number): number {
    if (delta > 0) {
      return 225;
    }

    if (delta === 0) {
      return 270;
    }

    if (delta < 0) {
      return 315;
    }

    return 270;
  }
}
