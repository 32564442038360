import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MarketColumnStore } from '../+state/column.store';
import { MarketColumn } from '../models/market-dashboard';
import { MarketGroupDataService } from './group.data.service';

@Injectable({ providedIn: 'root' })
export class MarketColumnDataService {
  public static readonly entityName = 'marketColumn';
  public static readonly columnGetParams = `
        id
        dashboardId
        name
        summaries
        {
            ${MarketGroupDataService.marketGroupParams}
        }
        details
        {
            id
            items
            {
                ${MarketGroupDataService.marketGroupParams}
            }
            position
            columnId
            name
        }
        position
    `;

  constructor(
    private store: MarketColumnStore,
    private graphqlService: GraphqlService
  ) {}

  getAll$(id: ID): Observable<MarketColumn[]> {
    const query = `
            query($id: Int!) {
                ${MarketColumnDataService.entityName}GetAll(id: $id) {
                    ${MarketColumnDataService.columnGetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketColumn[]>(query, { id });
  }

  getById$(id: ID): Observable<MarketColumn> {
    const query = `
            query($id: Int!) {
                ${MarketColumnDataService.entityName}GetById(id: $id) {
                    ${MarketColumnDataService.columnGetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketColumn>(query, { id });
  }

  add$(input: MarketColumn): Observable<MarketColumn> {
    const mutation = `
            mutation ($input: MarketColumnInput!) {
                ${MarketColumnDataService.entityName}Add(input: $input)
                {
                    ${MarketColumnDataService.columnGetParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketColumn>(mutation, variables)
      .pipe(tap((value: MarketColumn) => this.store.upsert(value.id, input)));
  }

  update$(input: MarketColumn): Observable<MarketColumn> {
    const mutation = `
            mutation ($input: MarketColumnInput!) {
                ${MarketColumnDataService.entityName}Update(input: $input)
                {
                    ${MarketColumnDataService.columnGetParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketColumn>(mutation, variables)
      .pipe(tap((value: MarketColumn) => this.store.upsert(value.id, value)));
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
            mutation {
                ${MarketColumnDataService.entityName}Delete(id: ${id})
            }
        `;

    return this.graphqlService
      .mutation<boolean>(mutation, {})
      .pipe(tap(() => this.store.remove(id)));
  }
}
