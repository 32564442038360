import { Injectable } from '@angular/core';
import { TableTimeSeries } from '@backoffice-frontend/time-series-identifier/api';
import { EnumValue } from '@clean-code/shared/bo/util-enum';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { MarketWidgetDataService } from '../infrastructure/widget.data.service';
import {
  MarketWidget,
  MarketWidgetDataSource,
} from './../models/market-dashboard';
@Injectable({ providedIn: 'root' })
export class WidgetFacade {
  constructor(private service: MarketWidgetDataService) {}

  add$(input: MarketWidget) {
    return this.service.add$(input);
  }

  update$(input: MarketWidget) {
    return this.service.update$(input);
  }

  getAll$(groupId: ID): Observable<MarketWidget[]> {
    return this.service.getAll$(groupId);
  }

  getById$(_id: ID) {
    return this.service.getById$(_id);
  }

  delete$(_id: ID) {
    return this.service.delete$(_id);
  }

  public timeFrames$(): Observable<EnumValue[]> {
    return this.service.timeFrames$();
  }

  public getDataSource(
    widget: MarketWidget,
    tableTimeSeries: TableTimeSeries
  ): MarketWidgetDataSource {
    const dataSource = widget.dataSources.find(
      (x: MarketWidgetDataSource) =>
        x.settings.identifier.identifierId === tableTimeSeries.identifierId &&
        x.settings.identifier.adjustment === tableTimeSeries.adjustment
    );

    if (!dataSource) {
      throw new Error('cannot find dataSource');
    }

    return dataSource;
  }
}
