<div
  class="icon-container"
  [ngStyle]="{
    transform: 'rotate(' + (rotate$ | async) + 'deg)',
    'transform-origin': 'center',
    color: getColor(rotate$ | async),
  }"
>
  <fa-icon
    [icon]="iconStyle === 'light' ? arrowIconLight : arrowIconSolid"
    [size]="iconSize"
  ></fa-icon>
</div>
