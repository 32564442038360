import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MarketDashboard } from '../models/market-dashboard';

export interface IMarketDasboardState extends EntityState<MarketDashboard> {
  editMode: boolean;
}

function createInitialState(): IMarketDasboardState {
  return {
    editMode: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboard', resettable: true })
export class MarketDashboardStore extends EntityStore<
  IMarketDasboardState,
  MarketDashboard
> {
  constructor() {
    super(createInitialState());
  }
}
