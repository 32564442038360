import { TitleOptions } from 'chart.js';

export class ChartHelper {
  public static createYAxisChartTitle(text: string): TitleOptions {
    const title = <TitleOptions>{
      text: text,
      display: true,
    };

    return title;
  }
}
