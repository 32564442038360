import { Injectable } from '@angular/core';
import { EnumValue, EnumValues } from '@clean-code/shared/bo/util-enum';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MarketDashboardStore } from '../+state/dashboard.store';
import { MarketDashboard } from '../models/market-dashboard';
import { MarketColumnDataService } from './column.data.service';

@Injectable({
  providedIn: 'root',
})
export class MarketDashboardDataService {
  public static readonly entityName = 'marketDashboard';
  public static readonly dashboardGetParams = `
        id
        name
        cols
        {
            ${MarketColumnDataService.columnGetParams}
        }
    `;

  constructor(
    private store: MarketDashboardStore,
    private graphqlService: GraphqlService
  ) {}

  public timeFrames$(): Observable<EnumValue[]> {
    const query = `
      query {
        __type(name: "TimeFrame"){
          name
          enumValues {
            name
          }
        }
      }
    `;

    return this.graphqlService
      .query<EnumValues>(query, null)
      .pipe(map((values: EnumValues) => values.enumValues));
  }

  getAll$(): Observable<MarketDashboard[]> {
    const query = `
            query{
                ${MarketDashboardDataService.entityName}GetAll {
                    id
                    name
                }
            }`;

    return this.graphqlService.query<MarketDashboard[]>(query);
  }

  getByName$(name: string): Observable<MarketDashboard> {
    const query = `
            query($name: String!) {
                ${MarketDashboardDataService.entityName}GetByName(name: $name) {
                    ${MarketDashboardDataService.dashboardGetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketDashboard>(query, { name });
  }

  getById$(id: ID): Observable<MarketDashboard> {
    const query = `
            query($id: Int!) {
                ${MarketDashboardDataService.entityName}GetById(id: $id) {
                    ${MarketDashboardDataService.dashboardGetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketDashboard>(query, { id });
  }

  add$(input: MarketDashboard): Observable<MarketDashboard> {
    const mutation = `
            mutation ($input: DashboardInput!) {
                ${MarketDashboardDataService.entityName}Add(input: $input)
                {
                    ${MarketDashboardDataService.dashboardGetParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketDashboard>(mutation, variables)
      .pipe(
        tap((value: MarketDashboard) => this.store.upsert(value.id, input))
      );
  }

  update$(input: MarketDashboard): Observable<MarketDashboard> {
    const mutation = `
            mutation ($input: DashboardInput!) {
                ${MarketDashboardDataService.entityName}Update(input: $input)
                {
                    ${MarketDashboardDataService.dashboardGetParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketDashboard>(mutation, variables)
      .pipe(
        tap((value: MarketDashboard) => this.store.upsert(value.id, value))
      );
  }

  delete$(_id: import('@datorama/akita').ID): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}
