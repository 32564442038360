import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { MarketColumnDetailDataService } from '../infrastructure/column-detail.data.service';
import { MarketColumnDetail } from './../models/market-dashboard';

@Injectable({ providedIn: 'root' })
export class MarketColumnDetailFacade {
  constructor(private service: MarketColumnDetailDataService) {}

  add$(input: MarketColumnDetail) {
    return this.service.add$(input);
  }

  update$(input: MarketColumnDetail) {
    return this.service.update$(input);
  }

  getAll$(columnId: ID): Observable<MarketColumnDetail[]> {
    return this.service.getAll$(columnId);
  }

  getById$(_id: ID) {
    return this.service.getById$(_id);
  }

  delete$(_id: ID) {
    return this.service.delete$(_id);
  }
}
