import { Injectable } from '@angular/core';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { TimeSeriesIdentifierInput } from '@backoffice-frontend/time-series-identifier/api';
import { Observable } from 'rxjs';
import { MarketDataWidgetService } from '../infrastructure/market-data-widget.data.service';
import {
  CorrelationModel,
  MarketChartCorrelation,
} from '../models/correlation.model';
import { CorrelationType } from '../models/widget-type-config';

@Injectable({ providedIn: 'root' })
export class CorrelationWidgetFacade {
  constructor(private service: MarketDataWidgetService) {}

  public marketTableCorrelation$(
    symbols: TimeSeriesIdentifierInput[],
    timeFrame: TimeFrame,
    weeks: boolean,
    months: boolean,
    years: boolean
  ): Observable<CorrelationModel[]> {
    return this.service.marketTableCorrelation$(
      symbols,
      timeFrame,
      weeks,
      months,
      years
    );
  }

  marketChartCorrelation$(
    baseDataSource: TimeSeriesIdentifierInput,
    symbols: TimeSeriesIdentifierInput[],
    timeFrame: TimeFrame,
    type: CorrelationType
  ): Observable<MarketChartCorrelation[]> {
    return this.service.marketChartCorrelation$(
      baseDataSource,
      symbols,
      timeFrame,
      type
    );
  }
}
