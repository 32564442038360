@if (isLoading$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
@if (data$ | async; as data) {
  <div class="flex flex-col lg:flex-row lg:h-56 space-x-4">
    <cc-line-chart
      [data]="data.chart"
      class="w-full lg:w-3/4"
      [legend]="legend()"
    ></cc-line-chart>
    <div class="w-full lg:w-1/4">
      <div class="text-center">
        {{ 'market-dashboard-details.MA_TITLE' | transloco }}
      </div>
      <table class="table-fixed">
        <tbody>
          @for (item of data.table; track item; let i = $index) {
            <tr>
              @if (i === 0) {
                <td class="w-56">
                  {{ 'market-dashboard-details.CLOSING_PRICE' | transloco }}
                </td>
              }
              @if (i > 0) {
                <td class="w-56">
                  {{
                    'market-dashboard-details.MA_NAME'
                      | transloco: { value: item.name }
                  }}
                </td>
              }
              <td class="w-28">{{ item.lastValue | number: '.2-2' }}</td>
              <td>{{ item.unit }}</td>
            </tr>
          }
        </tbody>
      </table>
      <ul class="list-disc !m-5">
        <li class="break-words">
          {{ 'market-dashboard-details.MA_20_TIP' | transloco }}
        </li>
        <li class="break-words">
          {{ 'market-dashboard-details.MA_200_TIP' | transloco }}
        </li>
      </ul>
    </div>
  </div>
}
