import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  DashboardDetailsFacade,
  DashboardFacade,
} from '@backoffice-frontend/market/domain';
import type { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';

import {
  LineChartBaseChart,
  TimeSeriesFacade,
  type TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';

import {
  DevExtremeLineChartComponent,
  type PointEventData,
  transformChartTimeSeriesData,
} from '@clean-code/shared/components/ui-devextreme-charts';
import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fof-index-chart',
  templateUrl: './index-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    MatProgressBarModule,
    TranslocoModule,

    DevExtremeLineChartComponent,
    VarDirective,
  ],
})
export class IndexChartComponent extends LineChartBaseChart<any> {
  private timeSeriesFacade: TimeSeriesFacade = inject(TimeSeriesFacade);
  public dashboardFacade: DashboardFacade = inject(DashboardFacade);
  public marketDashboardDetailsFacade: DashboardDetailsFacade= inject(DashboardDetailsFacade);

  public isTableLoading$ = new BehaviorSubject<boolean>(false);

  @Output() deliveryDayChanged = new EventEmitter<boolean>();
  selectedPointData$ = new Subject<Date>();

  @Input()
  public set identifier(input: TimeSeriesInput) {
    this.identifier$.next(input);
  }

  @Input()
  public set timeFrame(value: TimeFrame) {
    this.selectedTimeFrame$.next(value);
  }

  @Input()
  public hideClosingText = false;

  @Input()
  public legend = false;

  public data$ = this.paramChange$.pipe(
    switchMap(([value, _, timeFrame]: [TimeSeriesInput, any, TimeFrame]) => {
      return this.timeSeriesFacade
        .getTimeSeriesChartData$([value.identifier], timeFrame)
        .pipe(transformChartTimeSeriesData, indicate(this.isLoading$));
    }),
  );

  public detailsCompact$ = combineLatest([
    this.identifier$,
    this.selectedPointData$.pipe(startWith(null)),
  ]).pipe(
    switchMap(([value, date]: [TimeSeriesInput, Date]) =>
      this.marketDashboardDetailsFacade
        .getCompactIndexDetails$(value, date)
        .pipe(indicate(this.isTableLoading$)),
    ),
  );

  public details$ = combineLatest([
    this.identifier$,
    this.selectedPointData$.pipe(startWith(null)),
  ]).pipe(
    switchMap(([value, date]: [TimeSeriesInput, Date]) =>
      this.marketDashboardDetailsFacade
        .getIndexDetails$(value, date)
        .pipe(indicate(this.isTableLoading$)),
    ),
  );

  public calculationType$ = this.identifier$.pipe(
    map((x) => x.identifier.calculationType),
  );

  pointClickEvent($event: PointEventData) {
    this.selectedPointData$.next($event.date);
  }
}
