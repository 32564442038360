import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  IndividualTimeFrame,
  TimeFrame,
  TimeFrameStrict,
  TimeFrameUtilService,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { EnumValues } from '@clean-code/shared/bo/util-enum';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { SpreadPermission } from '@frontoffice/spread/domain';
import { TranslocoModule } from '@jsverse/transloco';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'cc-time-span-toggle',
  templateUrl: './time-span-toggle.component.html',
  styleUrls: ['./time-span-toggle.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    NgxPermissionsModule,

    TranslocoModule,
    UtilDateModule,
  ],
})
export class TimeSpanToggleComponent implements OnDestroy {
  timeFrameControl = new FormControl<TimeFrameStrict | null>(null);
  timeFrameControlSelect = new FormControl<TimeFrameStrict | null>(null);

  public timeFrame$: BehaviorSubject<TimeFrame>;
  public individualTimeFrame$ = new Subject<IndividualTimeFrame>();

  public permissions = SpreadPermission;

  private individualTimeFrameRecalculate$ = new Subject<TimeFrameStrict>();
  private closeSubject$ = new Subject<void>();

  private useWholeMonth = false;
  private useDeliveryDay = false;

  individualStartDateControl = new FormControl<Date | null>({
    value: null,
    disabled: true,
  });
  individualEndDateControl = new FormControl<Date | null>({
    value: null,
    disabled: true,
  });

  timeFrameChanges$ = merge(
    this.timeFrameControl.valueChanges,
    this.timeFrameControlSelect.valueChanges,
    this.individualTimeFrame$.asObservable(),
  ).pipe(
    debounceTime(300),
    filter((value: TimeFrame) => value && this.timeFrame$.value !== value),
    tap((value) => {
      this.timeFrame$.next(value);
    }),
  );

  @Input()
  public hideIndividualTimeFrame = false;

  @Input()
  public set timeFrame(timeFrame: BehaviorSubject<TimeFrame>) {
    this.timeFrame$ = timeFrame;
    if (this.timeFrame$.value !== this.timeFrameControl.value) {
      if (TimeFrameUtilService.isStrictTimeFrame(this.timeFrame$.value)) {
        this.timeFrameControl.patchValue(
          this.timeFrame$.value as TimeFrameStrict,
        );
        this.timeFrameControlSelect.patchValue(
          this.timeFrame$.value as TimeFrameStrict,
        );
        //individual is set via valueChanges events!
      } else {
        this.individualStartDateControl.patchValue(
          (<IndividualTimeFrame>this.timeFrame$.value).start,
        );
        this.individualEndDateControl.patchValue(
          (<IndividualTimeFrame>this.timeFrame$.value).end,
        );
      }
    }
  }

  @Input()
  public set individualTimeFrameCurrentMonthIncluded(value: boolean) {
    this.useWholeMonth = value;

    if (TimeFrameUtilService.isStrictTimeFrame(this.timeFrame$.value)) {
      this.individualTimeFrameRecalculate$.next(
        <TimeFrameStrict>this.timeFrame$.value,
      );
    }
  }

  @Input()
  public set deliveryDay(value: boolean) {
    this.useDeliveryDay = value;

    if (TimeFrameUtilService.isStrictTimeFrame(this.timeFrame$.value)) {
      this.individualTimeFrameRecalculate$.next(
        <TimeFrameStrict>this.timeFrame$.value,
      );
    }
  }

  timeFrames$ = this.graphqlService
    .query<EnumValues>(TimeFrameUtilService.timeFrameEnumQuery, null)
    .pipe(map((values: EnumValues) => values.enumValues));

  constructor(
    private graphqlService: GraphqlService,
    private deviceService: DeviceDetectorService,
    private dateAdapter: DateAdapter<any>,
  ) {
    this.dateAdapter.setLocale('de');
    this.timeFrameChanges$.pipe(takeUntil(this.closeSubject$)).subscribe();

    //on startup this is called multiple times, therefore debounce
    this.individualTimeFrameRecalculate$
      .pipe(
        debounceTime(200),
        tap((timeFrame) => this.setIndividualTimeFrameDates(timeFrame)),
        takeUntil(this.closeSubject$),
      )
      .subscribe();

    //keep timeframe controls (select and button group) in sync
    this.timeFrameControl.valueChanges
      .pipe(takeUntil(this.closeSubject$))
      .subscribe((v: TimeFrameStrict) => {
        //set individual
        this.individualTimeFrameRecalculate$.next(v);

        //sync
        this.timeFrameControlSelect.setValue(v, { emitEvent: false });
      });

    this.timeFrameControlSelect.valueChanges
      .pipe(takeUntil(this.closeSubject$))
      .subscribe((v: TimeFrameStrict) => {
        //set individual
        this.individualTimeFrameRecalculate$.next(v);

        //sync
        this.timeFrameControl.setValue(v, { emitEvent: false });
      });

    this.individualStartDateControl.valueChanges
      .pipe(
        debounceTime(200),
        filter((date) => !!date),
        takeUntil(this.closeSubject$),
      )
      .subscribe((_) => {
        setTimeout(() => {
          this.datePickerChanged(); //wait one cycle
        });
      });

    this.individualEndDateControl.valueChanges
      .pipe(
        debounceTime(200),
        filter((date) => !!date),
        takeUntil(this.closeSubject$),
      )
      .subscribe((_) => {
        setTimeout(() => {
          this.datePickerChanged(); //wait one cycle
        });
      });
  }

  public get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  private datePickerChanged() {
    //control.valid is not working as for disabled controls it is invalid (puke)
    //other controls set the values with emitEvent false for the individual controls
    //else the valuechanges event would be fired and we would reset all values in a loop
    if (
      this.individualStartDateControl.value &&
      this.individualEndDateControl.value
    ) {
      //reset others first
      this.timeFrameControl.setValue(null, { emitEvent: false });
      this.timeFrameControlSelect.setValue(null, { emitEvent: false });

      this.individualTimeFrame$.next({
        start: this.individualStartDateControl.value,
        end: this.individualEndDateControl.value,
      });
    }
  }

  private setIndividualTimeFrameDates(value: TimeFrameStrict) {
    const dates = TimeFrameUtilService.getIndividualTimeFrame(
      value,
      this.useWholeMonth,
      this.useDeliveryDay,
    );
    if (dates) {
      this.individualStartDateControl.setValue(dates.start, {
        emitEvent: false,
      });
      this.individualEndDateControl.setValue(dates.end, { emitEvent: false });
    }
  }

  public ngOnDestroy(): void {
    this.closeSubject$.next();
    this.closeSubject$.complete();
  }
}
