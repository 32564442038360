import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { MarketGroupDataService } from '../infrastructure/group.data.service';
import { MarketGroup } from '../models/market-dashboard';

@Injectable({ providedIn: 'root' })
export class GroupFacade {
  constructor(private service: MarketGroupDataService) {}

  add$(input: MarketGroup) {
    return this.service.add$(input);
  }

  update$(input: MarketGroup) {
    return this.service.update$(input);
  }

  getAll$(columnId: ID): Observable<MarketGroup[]> {
    return this.service.getAll$(columnId);
  }

  getById$(_id: ID) {
    return this.service.getById$(_id);
  }

  delete$(_id: ID) {
    return this.service.delete$(_id);
  }
}
