import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import sortBy from 'lodash-es/sortBy';
import { map } from 'rxjs/operators';
import { MarketGroup } from '../models/market-dashboard';
import { IMarketGroupState, MarketGroupStore } from './group.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'position',
  sortByOrder: Order.ASC,
})
export class MarketGroupQuery extends QueryEntity<
  IMarketGroupState,
  MarketGroup
> {
  public groups$ = (columnId: ID) =>
    this.selectAll({
      filterBy: (entity) => entity.summaryColumnId === columnId,
    });

  public detailsGroups$ = (columnId: ID) =>
    this.selectAll({
      sortBy: (entity) => entity.settings.position,
      sortByOrder: Order.ASC,
      filterBy: (entity) => entity.detailColumnId === columnId,
    }).pipe(
      map((values: MarketGroup[]) => sortBy(values, 'settings.position')),
    );

  constructor(store: MarketGroupStore) {
    super(store);
  }
}
